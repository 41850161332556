import React, { useEffect, useState } from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import SEO from "../components/seo";
import { get } from "lodash";
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { truncateDescription, markupToText } from "../utils/helpers";

export default function CategoryTemplate({ data, pageContext }) {
  const [loading, setLoading] = useState(true);
  const [posts, setPosts] = useState([]);
  const pageTitle = get(pageContext, "name") || "";
  const categories = get(data, "categories.nodes", null);
  // console.log(categories);

  const getDescription = (post) => {
    // console.dir(post);
    const postDescription = get(post, "posts.postDescription", null);
    const description = markupToText(postDescription);
    const newDescription = truncateDescription(description, 200);
    const excerpt = markupToText(get(post, "excerpt", ""));
    const newExcerpt = truncateDescription(excerpt, 200);

    if (newDescription) {
      return newDescription;
    }
    return newExcerpt;
  };

	useEffect(() => {
    // console.group("useEffect");
    const postsData = get(data, "category.contentNodes.nodes", null);
    // console.log(postsData);
    setPosts(postsData);
    setLoading(false);
    // console.groupEnd();
  }, []);


  return (
    <Layout baseRoute="category" blogCategories={pageContext.blogCategories}>
      <SEO title={pageTitle} description={pageTitle} />
      <h1>{pageTitle}</h1>
      {/* Categories menu */}
      <div className="categories-wrapper">
        {categories &&
          categories.map((category) => (
            <Link key={category.name} to={category.link}>
              {category.name}
            </Link>
          ))}
      </div>
      {/* /Categories menu */}
      <section id="posts-wrapper" className="contained">
        {!loading &&
          posts.map((post) => {
            return (
              <article key={post.id} className="bordered">
                <Link
                  to={`${post.uri}`}
                  className="title"
                  alt={post.altText}
                  key={post.id}
                >
                  {post.altText}
                  <GatsbyImage
                    layout="constrained"
                    image={getImage(post.featuredImage.node.localFile)}
                    alt={post.featuredImage.node.altText}
                  />
                  <h3>{post.title}</h3>
                </Link>
                <div className="description">
                  {getDescription(post)}
                  <div className="read-more-wrapper">
                    <span className="date">{post.date}</span>
                    <Link to={post.uri} className="read-more">
                      Read More ➢
                    </Link>
                  </div>
                </div>
              </article>
            );
          })}
      </section>
    </Layout>
  );
};




export const query = graphql`
  query ($id: String!) {
    category: wpCategory(id: { eq: $id }) {
      contentNodes {
        nodes {
          ... on WpPost {
            id
            uri
            title
            featuredImage {
              node {
                altText
                caption
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: DOMINANT_COLOR
                      formats: [AUTO, WEBP, AVIF]
                      transformOptions: { fit: COVER }
                      backgroundColor: "transparent"
                      layout: CONSTRAINED
                      aspectRatio: 1.78
                    )
                  }
                }
                description
              }
            }
            date(formatString: "MMMM D, YYYY")
            posts {
              postDescription
            }
            excerpt
            status
          }
        }
      }
    }
    categories: allWpCategory(filter: { count: { gt: 0 } }) {
      nodes {
        count
        name
        link
      }
    }
  }
`;
